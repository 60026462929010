/* 
====================================
              GLOBAL              
====================================
*/

:root, :root.light {
    
    --bg-primary: rgb(212, 228, 241);
    --bg-secondary: rgb(245, 249, 251);
    --bg-secondary-offset: rgb(230, 240, 244);

    --primary: rgb(21, 116, 91);
    --secondary: rgb(163, 217, 213);
    --secondaryRGB: 163, 217, 213;
    
    --text-on-primary: rgb(229, 227, 241);
    --text-on-secondary: black;
    --text-on-bg-primary: black;

    --danger: #dc3545;
    --success: #157f3c;
    --warn: #e7b008;
    --neutral: #0e7490;

    --greyOut: rgba(128, 128, 128, 0.422);
    --bg-overlay: rgba(0, 0, 0, 0.5);

    /* screen sizes for responsive design */
    --scLg: 992px;
    --scMd: 768px;
    --scSm: 576px;


    --inputYGap: 5px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

:root.dark {
    --bg-primary: rgb(27, 27, 29);
    --bg-secondary: rgb(0, 0, 1);

    --text-on-primary: white;
    --text-on-secondary: white;
    --text-on-bg-primary: white;
    --text-on-bg-secondary: white;
}

body {
    margin: 0;
}

#root {
    --pageOuterMargin: 10px;

    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: hidden;

    background-color: var(--bg-primary);
    color: var(--text-on-bg-primary);
}

.centerChildX {
    display: flex;
    justify-content: center;
}

.rightChildX {
    display: flex;
    justify-content: right;
}

.center-child-y {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-align-center {
    text-align: center;
}

.hover-pointer:hover {
    cursor: pointer;
}

.bg-primary {
    background-color: var(--bg-primary) !important;
}

.bg-danger {
    background-color: var(--danger) !important; 
}

.bg-success {
    background-color: var(--success) !important;  
}

.bg-warn {
    background-color: var(--warn) !important;
}

.bg-neutral {
    background-color: var(--neutral) !important;
}

.bg-grey {
    background-color: grey !important;
}

.color-primary {
    color: var(--primary);
}

.color-danger {
    color: var(--danger)
}

.d-none {
    display: none !important;
}

.opacity-0 {
    opacity: 0;
    pointer-events: none;
}

.d-flex {
    display: flex !important;
}

.space-between { 
    justify-content: space-between;
}

.gap-2 {
    gap: 2rem;
}

.flex-1 {
    flex: 1;
}

.h-100 {
    height: 100%
}

.w-100 {
    width: 100%;
}

.w-fit {
    width: fit-content;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mb-1 {
    margin-bottom: 10px;
}

.me-1 {
    margin-right: 10px !important;
}

.m-1 {
    margin: 10px !important;
}

.p-1 {
    padding: 10px;
}

.pe-1 {
    padding-right: 10px;
}

.ps-1 {
    padding-left: 10px;
}

.p-2 {
    padding: 2rem;
}

.align-self-start {
    align-self: flex-start !important;
}

.fullScrollableContainer {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    scrollbar-width: 0;
    -ms-overflow-style: none;
}

.fullScrollableContainer::-webkit-scrollbar {
    display: none;

    scrollbar-width: 0;
    -ms-overflow-style: none;
}

.errorMsg {
    color: var(--danger);
    white-space: pre-line;
}

.listCol2 {
    display: flex;
}

.listCol2 ul, .listCol2 li {
    flex: 1;
}

.profilePic {
    aspect-ratio: 1 !important;
}

.profilePic > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;

    background-color: var(--bg-overlay); 
}

.overlayChild {
    margin: auto;
    margin-top: 2rem;
    padding: 2rem;
    height: fit-content;
    width: fit-content;
    background-color: var(--bg-primary);

    border-radius: 5px;
}

.overlayTitle {
    font-weight: bold;
    font-size: 1.25rem;
}

.overlayButtonGroup {
    margin-top: 1rem;
    display: flex;
    gap: 20px;
}

.scroll-y {
    overflow-y: scroll;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-y::-webkit-scrollbar {
    width: 0;
}

/* fix IonIcon imports */
span:has(svg[xmlns*='w3.org']) {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* 
====================================
            COMPONENTS              
====================================
*/

.spinner {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}


/*
-----------------------------------
          scroll bar
-----------------------------------
*/

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}


/* 
-----------------------------------
          dividers              
-----------------------------------
*/

.shadowDivider {
    margin: auto;
    margin-top: 2rem;
    width: 80%;
    position: relative;
}
  
.shadowDivider .shadow {
    overflow: hidden;
    height: 20px;
}
  
.shadowDivider .shadow:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
}


/* 
-----------------------------------
          notification              
-----------------------------------
*/

.notificationContainer {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;
    width: 300px;
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes SlideRight {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 120%;
    }
}

.notification {
    --notificationBorderColor: transparent;
    border: 3px solid var(--notificationBorderColor);
    box-shadow: 0 0 5px rgba(0, 0, 0, .4);
    border-radius: 10px;
    background-color: var(--bg-secondary);
    overflow: hidden;
    margin-bottom: 10px;

    width: 300px;   

    animation: SlideLeft 1s;
    animation-fill-mode: forwards;
    cursor: pointer;
}

.notification.exit {
    animation: SlideRight 1s;
    animation-fill-mode: forwards;
}

.notification .content {
    padding: 10px;
}

.notification .bar {
    height: 10px;
    background-color: var(--primary);
}


/* 
====================================
         Splash Screen              
====================================
*/

.splashScreenLoader {
    position: absolute;

    top: 50%;
    left: 50%;

    height: 6rem;
    aspect-ratio: 1;

    transform: translate(-50%, -50%);
}


/* 
====================================
            LOGIN              
====================================
*/

.loginContainer {
    margin: auto;
    margin-top: 3rem;

    padding: 4rem;
    padding-bottom: 2rem;

    width: 10rem;
    height: fit-content;

    background-color: var(--bg-secondary);
    border-radius: 15px;
}

.loginContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    border-radius: 5px;
    border: 1px rgba(0, 0, 0, 0.182) solid;
}

.loginForm {
    width: 100%;
    text-align: center;
}

.loginInput {
    margin-bottom: 10px;
}


/* 
====================================
            DASHBOARD              
====================================
*/

.dashboardContainer {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: var(--pageOuterMargin);

    box-sizing: border-box;
}

.dashboardMainContainer {
    --widgetsGap: 1.5rem;
    flex: 1;

    padding: 0 var(--widgetsGap) var(--widgetsGap) var(--widgetsGap);

    background-color: var(--bg-primary);
    border-radius: 10px;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dashboardMainContainer::-webkit-scrollbar {
    display: none;
}

.dashboardWidgetContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    gap: var(--widgetsGap);
}

.dashboardGreeting {
    text-align: center;
}


/* 
-----------------------------------
             sidebar              
-----------------------------------
*/

.sidebar {
    --itemGap: .25rem;
    background-color: var(--bg-secondary);
    position: relative;

    display: flex;
    align-items: center;
    gap: .5rem;
    
    max-height: 5rem;
    width: 100%;

    padding: 5px;
    box-sizing: border-box;
    border-radius: 10px;

    z-index: 50;
    box-shadow: 0px 0px 10px grey;
} 

.sidebarMenuButton {
    display: none;
    cursor: pointer;
}

.sidebarMenuButton svg {
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
}

.sidebarMenu {
    position: absolute;
    top: calc(100% + 10px);
    width: fit-content;
    height: fit-content;
    max-height: 50vh;
    right: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    background-color: var(--bg-secondary);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateX(calc(100% + var(--pageOuterMargin)));

    transition: transform 500ms ease-in-out, box-shadow 500ms;
}

.sidebarMenu.open {
    transform: translateX(-(calc(100% + var(--pageOuterMargin))));
    box-shadow: -5px 5px 8px grey;
    display: none;
}

.sidebarMenuContents {
    padding: 5px 10px 5px 10px;
}

.sidebarScrollView {
    height: 100%;
    gap: var(--itemGap);
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-right: 10px;

    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebarScrollView::-webkit-scrollbar {
    display: none;
}

.sidebarItem {
    all: unset;
    display: flex;
    gap: 5px;
    
    font-size: 1.25rem;
    overflow: hidden;
    min-width: fit-content;

    border-radius: 5px;
    padding: 10px;
    align-items: center;
}


.sidebarItem:first-child {
    margin-top: 0;
}

.sidebarItem:hover {
    background-color: var(--primary);
    cursor: pointer;
    color: var(--text-on-primary);
}

.sidebarItem:hover svg {
    filter: invert(1); 
}

.sidebarLogo { 
    height: 80%;
    margin-right: 10px;
    padding: .25rem;
    aspect-ratio: 1;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .sidebar {
        justify-content: space-between;
    }

    .sidebarScrollView {
        display: none;
    }

    .sidebarMenuButton {
        display: block;
    }

    .sidebarMenu.open {
        display: block;
    }
}


/* 
-----------------------------------
            widgets              
-----------------------------------
*/

.widgetMd {
    grid-column: span 2;
}

.widgetLg {
    grid-column: span 3;
}

.widgetTall {
    grid-row: span 2;
}

.widgetTaller {
    grid-row: span 3;
}

.widget {
    background-color: var(--bg-secondary);
    width: 100%;
    min-height: 10rem;

    border-radius: 15px;
}

@media screen and (max-width: 1072px) {
    .dashboardWidgetContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .widgetLg {
        grid-column: span 2;
    }
}

@media screen and (max-width: 797px) {
    .dashboardWidgetContainer {
        grid-template-columns: minmax(0, 1fr);
    }
    .widgetLg, .widgetMd {
        grid-column: span 1;
    }
}


/* 
-----------------------------------
          weather widget              
-----------------------------------
*/

.weatherWidget {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: 100%;
}

.weatherWidgetHeader {
    margin: 10px;

    display: flex;
    
    justify-content: space-between;
}

.weatherWidgetTitle {
    text-align: center;
    margin: 0 10px 0 10px;
    
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;
}

.weatherWidgetButton {
    width: 22px;
    position: relative;
}

.weatherWidgetBody {
    display: flex;
    flex: 1;

    align-items: center;
}

.weatherWidgetCol {
    flex: 1;
    display: flex;
    flex-direction: row;

    gap: 1rem;

    align-items: center;
    justify-content: center;

    height: 100%;
}

.weatherWidgetCol h3 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.weatherWidgetCol div {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* 
-----------------------------------
            graphs              
-----------------------------------
*/

.graphWidgetHeader {
    margin: 10px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    overflow: hidden;
}

.graphContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    padding: 10px;
    height: 20rem;
}


/* 
-----------------------------------
         daily jobs card              
-----------------------------------
*/

.dailyJobsTitle {
    color: var(--primary);
}

.dailyJobsNone {
    width: 100%;

    padding: 1px 0 1px 0;

    text-align: center;

    border-radius: 15px;

    background-color: var(--bg-primary);
    color: var(--primary)
}

.dailyJobsContainer {
    display: flex;

    gap: 1rem;

    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dailyJobsContainer::-webkit-scrollbar {
    display: none;
} 

.dailyJobsCard {
    --bannerColor: var(--neutral);
    min-width: 11rem;
    height: 13rem;

    border-radius: 5px;

    display: flex;
    flex-direction: column;
    position: relative;

    border-top: 8px solid var(--bannerColor);

    background-color: var(--bg-secondary);
}

.dailyJobsHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 15px;
    pointer-events: none;
}

.dailyJobsHeader b, .dailyJobsHeader i {
    max-width: 100%;
    margin: 0 10px 0 10px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;
}

.dailyJobsBody {
    margin: 10px;
    margin-top: 1rem;
    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;
    pointer-events: none;
}

.dailyJobsBody > button {
    pointer-events: auto;
}

.dailyJobsBody span {

    margin-bottom: 5px;

    max-width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;
}


/* 
-----------------------------------
         unassigned jobs              
-----------------------------------
*/

.unassignedJobsLabel {
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.unassignedJobsContainer {
    box-sizing: border-box;
    margin: 1rem;
    max-height: 11rem;
    width: unset;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.unassignedJob {
    border: 3px solid var(--primary);
    border-radius: 10px;
    padding: 5px;
}

.unassignedJob:hover {
    border-color: var(--secondary);
}

.unassignedJobHeader {
    display: flex;
    justify-content: space-between;
}

.unassignedJobCustomer {
    font-size: 1.25rem;
    font-weight: bold;
}


/* 
-----------------------------------
           calendar              
-----------------------------------
*/

.calendarContainer {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.calendarTable {
    table-layout: fixed;
    text-align: center;
    padding: .5rem;
    border-radius: inherit;
    width: 100%;
}

.calendarTableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem .5rem 1rem;
}

.calendarDOW {
    font-weight: normal;
}

.calendarCell {
    border-radius: 50%;
    aspect-ratio: 1 !important;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.calendarCell.outsideMonth {
    color: grey; 
}

.calendarCell.active {
    background-color: var(--primary);
    color: var(--text-on-primary)
}

.calendarCell.today {
    border: 2px solid var(--primary);
}

.calendarJobsContainer {
    margin: 1.5rem;
    border-top: solid black 2px; 

    flex: 1;

    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1rem;
    max-height: 20rem;

    overflow-y: scroll;
}

.calendarJobsElement {
    border: 3px solid var(--primary);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.calendarJobsElement:hover {
    border-color: var(--secondary);
}

.calendarJobsElementHeader { 
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 1rem;
}

.calendarJobsElement h2 {
    margin: 0;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary);

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.calendarJobsElement span {
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.75rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.calendarJobsElementDescription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* 
====================================
           MANAGE PAGES              
====================================
*/

/**
unused classes: 
.manageCardHeader
**/

.manageBackground {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.manageContainer {
    width: 100%;
    max-height: 100%;

    display: flex;
    justify-content: center;

    overflow: scroll;
    scrollbar-width: 0;
    -ms-overflow-style: none;
}

.manageContainer::-webkit-scrollbar {
    width: 0;
}

.manageCard {
    z-index: 5;
    display: flex;
    flex-direction: column;
    position: relative;

    height: fit-content;
    min-width: 30%;

    background-color: var(--bg-primary);

    border-radius: 15px;
    margin-top: 5rem;
    
    box-sizing: border-box;
    padding: 2rem;
}

.manageCardBackArrow {
    position: absolute;
    top: 10px;
    left: 10px;
}

.manageCardBackArrow:hover {
    cursor: pointer;
}

.manageCardHeader {
    margin-top: 10px;
}

.manageCardHeader h3 {
    margin-top: 0;
}

.manageCardBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px
}

.manageCardBody h3 {
    margin-bottom: 0;
}

.manageCardFooter {
    box-sizing: border-box;
    padding: 2.5rem 0 .75rem;
}

.manageCardError {
    transform: translateY(-1rem);
    text-align: center;
}

.manageButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.manageStepsContainer {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.cardStep {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    background-color: rgb(199, 198, 198); 
    
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.cardStep span {
    height: 22px;
    width: 22px;
}

.cardStep.active {
    filter: brightness(85%);
}

.cardStep.finish {
    background-color: var(--success);
}

.manageDroppable {
    max-height: 25vh;
    overflow-y: scroll;
    width: 13rem;

    scrollbar-width: 0;
    -ms-overflow-style: none;
}

.manageDroppable::-webkit-scrollbar {
    width: 0;
}

.manageConfirm {
    max-width: 30rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}


/* 
-----------------------------------
            jobs              
-----------------------------------
*/

.jobsConfirmDate {
    align-self: flex-end;

    display: flex;
    align-items: center;
    
    margin-right: 20px;
    gap: 10px;
}

.jobsConfirmSlider>.sliderToggleLabel {
    width: 2.75rem
}

.jobsStateDropDown {
    background-color: var(--bg-secondary);
    padding: .5rem;
    border-radius: 10px;
}

.jobsCustomerHeader {
    text-align: center;
}

.jobsCustomerHeader b {
    display: block;
    font-size: 1rem;
}


/* 
-----------------------------------
            users              
-----------------------------------
*/

.manageUsersNameToggle {
    display: flex;
    font-size: .8rem;
}

.manageUsersNameToggle label {
    width: 2rem;
}

.manageUsersNameToggle span { 
    margin-right: 10px;
}

.manageUsersButtonSecondary.manageUsersButtonSecondary {
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--text-on-bg-primary);
}


/* 
-----------------------------------
         entitySelector              
-----------------------------------
*/

.entitySelectorContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.entitySelectorMain {
    max-height: 30vh;
    overflow-y: scroll;
}

.entitySelectorGroup {
    display: flex;
    flex-direction: column;
    padding: 0 .5rem 0 .5rem;
    
    gap:.5rem;
    padding-bottom: 1.5rem;
}

.entitySelectorItem {
    padding: .5rem;

    border: 3px solid var(--secondary);
    border-radius: 7px;
}

.entitySelectorItem.active {
    border-color: var(--primary);
}


/* 
====================================
           ENTITY VIEW              
====================================
*/

.entityViewContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.entityViewTitleBar {
    width: 100%;
    height: 4rem;
    background-color: var(--bg-secondary);

    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 .5rem 0 .5rem
}

.entityViewTitleButtons {
    display: flex;
    gap: .5rem;
}

.entityViewTitleButton {
    all: unset;
    
    padding: .5rem;

    border: none;
    border-radius: 5px;

    text-align: center;

    transition: box-shadow 0.3s ease-in-out;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0);
}

.entityViewTitleButton > svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
}

.entityViewTitleButton:hover {
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
}

.entityViewStateDropDown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entityViewStateDropDownContentContainer {
    position: absolute;
    top: 100%;
    z-index: 9999;
    width: max-content;

    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows .5s, padding .3s;
    padding: 0 .5rem 0 .5rem;


    background-color: var(--bg-secondary);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.entityViewStateDropDownContentContainer.open {
    grid-template-rows: 1fr;
    padding: .5rem;
}

.entityViewStateDropDownContent {
    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow: hidden;
}

.entityViewTitleButtonsDivider {
    border-right: 1px solid rgba(0, 0, 0, 0.4); 
}

.entityViewBody {
    position: relative;
    height: 100%;
    width: 100%;

    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: 0;
    -ms-overflow-style: none;
}

.entityViewBody::-webkit-scrollbar {
    display: none;
}

.entityViewJobAsigned {
    width: 100%;
    max-height: 10rem;

    box-sizing: border-box;

    display: flex;
    justify-content: space-evenly;

    padding-top: 2rem;
}

.entityViewJobAsigned > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;
}

.entityViewJobAsigned ul {
    padding-left: 10px;
}

.entityViewJobBody {
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    min-height: 100%;
    width: 100%;
}

.entityViewJobDivider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4); 
    width: 70%;
    margin: 20px 0 10px;
}

.entityViewJobDate {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.entityViewJobItem {
    margin-top: 2rem
}

.entityViewRightSidebar {
    --width: 18rem;
    --padding: 20px;

    position: absolute;
    width: var(--width);
    height: 100%;
    top: 0;
    bottom: 0;
    left: 100%;

    overflow-y: scroll;
    overflow-x: hidden;

    background-color: var(--bg-secondary-offset);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 var(--padding) 0 var(--padding);

    transition: left 500ms ease-in-out, box-shadow 500ms;
}

.entityViewRightSidebar.open {
    left: calc(100% - (var(--width) + var(--padding)*2));
    box-shadow: 0 -10px 10px grey;
}

.entityViewJobNote {
    width: 100%;
    text-align: center;
    background-color: var(--bg-primary);
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid black; 
}

.entityViewJobNote > div {
    padding: 10px;
}

.entityViewJobNoteHeader {
    position: relative; 
    padding-left: 2rem !important;
    padding-right: 2rem !important;
} 

.entityViewJobNoteHeaderX {
    position: absolute; 
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

/*.entityViewJobNoteFooter {
    
}*/

.entityViewJobNoteBody {
    border-top: 1px black solid; 
    border-bottom: 1px black solid; 
    padding: 10px 0 10px;
    background-color: var(--bg-secondary-offset);
}

.jobEntityPicturesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
    padding-bottom: 10px;
}

.jobEntityPicturesInput {
    padding-bottom: 35px;
}

.jobEntityPictureWrapper {
    position: relative;
}

.jobEntityPictureWrapper:hover > .jobEntityPictureMetadata {
    display: grid;
}

.jobEntityPictureDelete {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: var(--bg-overlay);
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.jobEntityPictureDelete > * {
    color: white !important;
}

.jobEntityPictureMetadata {
    position: absolute;
    bottom: 3.5px;

    display: none;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
    box-sizing: border-box;
    padding: .5rem;

    background-color: var(--bg-overlay);
    color: white;
}

.jobEntityPictureMetadata > div {
    display: flex;
    align-items: end;
}

.jobEntityPictureMetadata > div:last-child {
    justify-content: flex-end;
    white-space: pre-line;
    text-align: center;
}

.jobEntityPicture {
    max-width: 100%;
    object-fit: contain;
}

.entityViewCardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 1rem 0 1rem;
}

.entityViewCard {
    width: 40rem;
    max-width: 80%;
    aspect-ratio: 2;
    align-self: center;
    margin: auto;
    margin-top: 2rem;
    box-sizing: border-box;

    background-color: var(--bg-secondary-offset);
    border-radius: 30px;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 2rem;
}

.entityViewCard > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.entityViewCard > div:first-child {
    border-right: 1px solid black; 
}

.entityViewCard > div:last-child {
    justify-content: center;
}

.entityViewCardName > h2, .entityViewCardName > h3 {
    margin-bottom: 0;
}
.entityViewCardName > h3 {
    margin-top: 10px;
}

.entityViewCardPic {
    height: 6.5rem;
}

.entityViewCardPic > img {
    border: 3px solid black; 
}

.entityViewCardRightContainer {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 650px) {
    .entityViewCard {
        grid-template-columns: minmax(0, 1fr);
    }

    .entityViewCard > div:first-child {
        border-right: none;
        border-bottom: 1px solid black;
        padding-bottom: 1rem;
    }
}


/* 
====================================
            ARCHIVE              
====================================
*/

.archiveContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.archiveSidebar {
    background-color: var(--bg-secondary);
    padding: 10px;
    display: flex;
    gap: 1rem;
}

.archiveFiltersDropDownContainer {
    background-color: var(--bg-secondary);
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 800ms, padding 800ms;
    padding: 0 1rem 0 1rem;
}

.archiveFiltersDropDownContainer.open {
    grid-template-rows: 1fr;
    padding: 1rem;
}

.archiveFiltersDropDown {
    overflow: hidden;
    display: flex;
    gap: 3rem;
    row-gap: 1.25rem;
    flex-wrap: wrap;
}

.archiveFiltersGroup > .title {
    font-weight: bold;
    font-size: 1.25rem;
}

.archiveDateFilters {
    display: flex;
    gap: 10px;
}

.archiveDateClear.archiveDateClear {
    font-weight: unset;
    font-size: .8rem;
    padding: 5px;
    margin-left: 10px;
}

.archiveDateFilters > span {
    font-weight: bold;
    font-size: 1.5rem;
}

.archiveFiltersContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
}

.archiveFiltersContainer h4 {
    margin: 0;
}

.archiveFiltersContainer > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.archiveFiltersChevron {
    background-color: var(--bg-secondary);
    width: fit-content;
    align-self: center;
    padding: 3px 2rem 3px 2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.archiveMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.archiveSearch {
    width: 100%;
    height: 5rem;
    font-size: 1.25rem;
    padding: 1rem;
    box-sizing: border-box;
}

.archiveResults {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 1rem 2rem 10px 2rem;

    overflow-y: scroll;
    box-shadow: inset 0px 0px 8px -5px var(--bg-overlay);
}

.archiveCard {
    display: flex;
    gap: 1rem;
    align-items: center;
    border-bottom: 3px solid rgba(128, 128, 128, .4);
    padding-bottom: 10px;
}

.archiveCardBody {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.archiveProfilePicture {
    height: 4rem;
    aspect-ratio: 1;
    border-radius: 200%;
}

.archiveCardContext {
    font-size: 1rem;
    font-style: italic;
}

.archiveCardTitle {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--primary);
}

.archiveCardPills {
    display: flex;
    gap: 10px;
}

.archiveCardPill {
    border: 3px solid var(--borderColor, grey);
    width: fit-content;
    padding: 4px;
    border-radius: 999rem;
    font-size: .85rem;
    font-weight: bold;
}


/* 
====================================
           MANAGE PAGES              
====================================
*/

.settingsContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    box-sizing: border-box;

    padding: 1rem;
}

.settingsTypes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-secondary);

    box-shadow: 5px 7px 8px grey;

    border-radius: 10px;
}

.settingsTypesButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.settingsTypeButton {
    border: solid 2px transparent;
    border-radius: 9999rem;
    padding: .5rem 1rem .5rem 1rem;

    font-size: 1.25rem;
    cursor: pointer;
}

.settingsTypeButton:hover, .settingsTypeButton.active {
    border-color: var(--primary);
}

.settingsTypeButton.active:hover {
    border-color: var(--secondary);
}

.settingsSave.settingsSave {
    position: absolute;
    bottom: 10px; 
    right: 10px;
}

.settingsLogout.settingsLogout {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: var(--danger);
}

.settingsOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settingText {
    display: flex;
    align-items: center;
}

.settingGrid {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: repeat(5, minmax(0, 2rem));

    gap: 1rem;
    column-gap: 2rem;    
}   

.setting {
    display: flex;
    align-items: center;
    gap: 1rem;

    white-space: nowrap;
}

@media screen and (max-width: 700px) {
    .settingsContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 3fr;
    }

    .settingsTypesButtons {
        flex-direction: row;
        margin-bottom: 20px;
    }

    .settingsSave.settingsSave {
        bottom: unset;
        top: 10px;
    }

    .settingsLogout.settingsLogout {
        position: fixed;
        
        left: unset;
        margin: auto;
    }
}


/* 
====================================
           MANAGE PAGES              
====================================
*/

.messagesContainer {
    display: flex;
    height: 100%;
}

.messagesSideBar {
    flex: 2;
    background-color: var(--bg-secondary); 

    display: flex;  
    flex-direction: column;
    align-items: center;
}

.messagesSideBarOptions {
    display: flex;
    width: 100%;
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.messagesSideBarDMContainer {
    width: 100%;
    box-sizing: border-box;
    padding-left: 7px;
    overflow-y: scroll;
}

.messagesSideBarDM {
    display: grid;
    grid-template-columns: auto minmax(0, 2fr) minmax(0, 1fr);
    gap: .5rem;
    margin: 5px;
    padding: 5px;

    border: 3px solid transparent;
    border-radius: 10px;
}

.messagesSideBarDM:hover {
    cursor: pointer;
}

.messagesSideBarDM.selected, .messagesSideBarDM:hover {
    border-color: var(--primary);
}

.messagesSideBarDM.selected:hover {
    border-color: var(--secondary);
}

.messagesSideBarDM > img {
    max-height: 3rem;
    aspect-ratio: 1;
    border-radius: 99999rem;
}

.messagesSideBarDMText > span,b {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;
}

.messagesSideBarDMText > b {
    -webkit-line-clamp: 2;
}

.messagesSideBarDMDate {
    text-align: end;
}

.messagesMain {
    flex: 3;

    display: flex;
    flex-direction: column;

    position: relative;
}

.messagesNewDMContainer {
    position: absolute;
    z-index: 100;
    top: 0;

    width: 100%;
}

.messagesChat {
    flex: 1;

    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;

    padding: .5rem;

   /* TODO consider these styles
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    box-shadow: 0px 20px 20px -20px inset;*/
}

.messagesChatMessage {
    font-size: .9rem;
}

.messagesChatMessage.isSelf {
    align-self: flex-end;
    text-align: end;
}

.messagesChatMessageText {
    background-color: var(--primary);
    color: var(--text-on-primary);
    width: fit-content;
    max-width: 75;

    padding: .5rem;
    border-radius: 10px;
    font-size: 1rem;
}

.messagesInput {
    position: fixed;
    bottom: 0;
    margin: 0 1rem 0 1rem;
}


/* 
====================================
            Scraper              
====================================
*/

.scraperLoginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: auto;
    margin-top: 4rem;
    width: fit-content;
    background-color: var(--bg-secondary);
    border-radius: 10px;
    padding: 2rem;
}

.scraperContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.carouselCard:has(> .scraperJob.scraperConfirmed) {
    border: 3px solid var(--success);
}

.carouselCard:has(> .scraperJob.scraperExcluded) {
    border: 3px solid var(--danger);
}

.scraperTitleBar {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-secondary);
    padding: 10px;
    box-shadow: 0 0px 10px 3px grey;
    z-index: 99;
}

.scraperTitleBar > div { 
    display: flex;
    gap: 10px;
}

.scraperJob {
    background-color: var(--bg-secondary);
    border-radius: 10px;
    padding: 15px;
    transition: box-shadow 500ms ease-out;
}

.scraperJobHeader { 
    display: flex;
    justify-content: space-between;
}

.scraperJobTitle {
    margin-top: .5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.scraperJobBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    gap: 1rem;
}

.scraperControlButtons {
    align-self: center;
    flex: 1;
    display: flex;
    align-items: flex-end;
    gap: 1.5rem;
    margin: 20px;
}

.scraperControlButtons.outter {
    flex: unset;
    margin-top: 2rem;
}


/* 
====================================
         Carousel              
====================================
*/

.carouselContainer {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin: 20px 0 20px;
}

.carouselCard {
    background-color: var(--bg-secondary);
    border-radius: 10px;
    box-shadow: -5px 10px 15px grey;
    z-index: 10;
    
    grid-row: 1;
    grid-column: 2;
    transition: all 800ms, opacity 300ms, border-width 0s, border-color 0s;
    display: flex;
    flex-direction: column;
}

.carouselCard:not([data-pos="2"]) {
    z-index: 5;
    opacity: 60%;
    transition: all 900ms, opacity 2s, z-index 0s;
}

.carouselCard[data-pos='1'] {
    transform: translate(-25%);
    scale: 80%;
}

.carouselCard[data-pos='3'] {
    transform: translate(25%);
    scale: 80%;
}

.carouselCard[data-pos='hidden'] {
    scale: 60%;
    opacity: 0;
}

.carouselButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 20px;
}

.carouselButton {
    width: 2.5rem;
    aspect-ratio: 1;
    background-color: var(--primary);
    border-radius: 150%;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.carouselButton * {
    pointer-events: none;
}


/* Expand */

.carouselContainer.expand {
    row-gap: 1.5rem;
}

.carouselCard.expand {
    grid-row: unset;
    opacity: unset;
    scale: unset;
    transform: unset;
}

.carouselButtonContainer.expand {
    display: none;
}

